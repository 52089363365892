import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { OidcUserStatus, useOidcAccessToken, useOidcUser } from '@axa-fr/react-oidc-context'

import TimeaWebchat from 'features/chat/webchat/TimeaWebchat'
import { getUserName } from 'utils/auth/functions'
import { connectToChatbot } from './webchat/functions'

const ChatLoggedIn = ({
  isAnonymous,
  urlConfig,
  displayHeader,
  displayChatbotName,
  headerFollowupText,
  hideUploadButton,
  hideNewConversationButton,
  botAvatar,
  soundUrl,
  forceAutoscrollOnNewMessage,
  isDevelopment,
  headerChatVersion,
  emojiSet
}) => {
  const { oidcUser, oidcUserLoadingState } = useOidcUser()
  const { accessToken } = useOidcAccessToken()
  const displayUserName = getUserName(oidcUser)

  const handleConnectToDirectline = useCallback(
    (userID, username, fullTokenUrl, getNewConversation, setDirectline) => {
      connectToChatbot(isAnonymous, fullTokenUrl, userID, username, accessToken, getNewConversation, setDirectline)
    },
    [accessToken, isAnonymous]
  )

  return (
    <div id='tswebchat_container'>
      {oidcUserLoadingState === OidcUserStatus.Loaded && (
        <TimeaWebchat
          isAnonymous={isAnonymous}
          urlConfig={urlConfig}
          displayHeader={displayHeader}
          displayChatbotName={displayChatbotName}
          displayUserName={displayUserName}
          headerFollowupText={headerFollowupText}
          hideUploadButton={hideUploadButton}
          hideNewConversationButton={hideNewConversationButton}
          botAvatar={botAvatar}
          soundUrl={soundUrl}
          forceAutoscrollOnNewMessage={forceAutoscrollOnNewMessage}
          isDevelopment={isDevelopment}
          headerChatVersion={headerChatVersion}
          emojiSet={emojiSet}
          handleConnectToDirectline={handleConnectToDirectline}
        />
      )}
    </div>
  )
}

ChatLoggedIn.propTypes = {
  isAnonymous: PropTypes.bool.isRequired,
  urlConfig: PropTypes.object.isRequired,
  displayHeader: PropTypes.bool,
  displayChatbotName: PropTypes.object.isRequired,
  headerFollowupText: PropTypes.object.isRequired,
  hideNewConversationButton: PropTypes.bool.isRequired,
  hideUploadButton: PropTypes.bool,
  botAvatar: PropTypes.object,
  soundUrl: PropTypes.string,
  forceAutoscrollOnNewMessage: PropTypes.bool,
  isDevelopment: PropTypes.bool,
  headerChatVersion: PropTypes.string,
  emojiSet: PropTypes.bool || PropTypes.object
}

export default ChatLoggedIn
