import { DIRECT_LINE_POST_ACTIVITY } from 'constants/webchat/activityNames'

export const sendFilesToChatbot = async (dispatch, action, uploadUrl, logsUrl, token = null) => {
  let uploadedAttachments = []
  for (let file of action.payload.files) {
    const { name, url: localBlobUrl, thumbnail, size } = file
    let contentUrl = await uploadFileToChatbot(name, localBlobUrl, uploadUrl, logsUrl, token)
    if (contentUrl) {
      let attachment = {
        contentUrl,
        name
      }
      if (thumbnail) {
        const contentType = extractContentTypesFromBase64(thumbnail)
        attachment.contentType = contentType
        attachment.thumbnailUrl = thumbnail // It is data:base64
      }
      uploadedAttachments.push({ attachment, size })
    }
  }

  const activity = {
    name: 'webchat/upload',
    type: 'message',
    attachments: null,
    attachmentsWebchat: uploadedAttachments.map(({ attachment }) => attachment),
    channelData: {
      attachmentSizes: uploadedAttachments.map(({ size }) => size)
    }
  }
  dispatch({
    type: DIRECT_LINE_POST_ACTIVITY,
    meta: { method: 'keyboard' },
    payload: {
      activity
    }
  })
}

export const uploadFileToChatbot = async (name, localBlobUrl, uploadUrl, logsUrl, token = null) => {
  let headers = {
    'Content-Type': 'application/octet-stream'
  }
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  let fileArrayBuffer
  try {
    const response = await fetch(localBlobUrl)

    const log = !response.ok
      ? {
          level: 'info',
          message: `Loaded blob data from ${localBlobUrl} for file: ${name}.\nHTTP Response: Status: ${response.status}, StatusText: ${
            response.statusText
          }, Ok: ${response.ok}, Headers: ${JSON.stringify(response.headers, null)}, URL: ${response.url}, Type: ${
            response.type
          }, BodyUsed: ${response.bodyUsed}, Redirected: ${response.redirected}`
        }
      : {
          level: 'error',
          message: `Loaded blob data from ${localBlobUrl} for file: ${name}.\nHTTP Response: Status: ${response.status}, StatusText: ${
            response.statusText
          }, Ok: ${response.ok}, Headers: ${JSON.stringify(response.headers, null)}, URL: ${response.url}, Type: ${
            response.type
          }, BodyUsed: ${response.bodyUsed}, Redirected: ${response.redirected}`
        }

    if (token) {
      logWebchatHost(logsUrl, token, log)
    }

    if (!response.ok) {
      return null
    }

    fileArrayBuffer = await response.arrayBuffer()
  } catch (error) {
    console.error('Failed to fetch local blob URL.', error)
    const log = {
      level: 'error',
      message: `Failed to fetch local blob URL: ${localBlobUrl} for file: ${name}.\nError: ${error}`
    }
    if (token) {
      logWebchatHost(logsUrl, token, log)
    }
    return null
  }

  try {
    const uploadResponse = await fetch(`${uploadUrl}?name=${name}`, {
      method: 'POST',
      headers: headers,
      body: fileArrayBuffer
    })
    if (!uploadResponse.ok) {
      const log = {
        level: 'error',
        message: `Failed to upload file: ${name}.\nHTTP Response: Status: ${uploadResponse.status}, StatusText: ${
          uploadResponse.statusText
        }, Ok: ${uploadResponse.ok}, Headers: ${JSON.stringify(uploadResponse.headers, null)}, URL: ${uploadResponse.url}, Type: ${
          uploadResponse.type
        }, BodyUsed: ${uploadResponse.bodyUsed}, Redirected: ${uploadResponse.redirected}`
      }
      if (token) {
        logWebchatHost(logsUrl, token, log)
      }
      return null
    }
    const result = await uploadResponse.json()
    return result?.uploadUrl
  } catch (error) {
    console.error('Failed to upload file to chatbot.', error)
    const log = {
      level: 'error',
      message: `Failed to upload file to chatbot: ${name}.\nError: ${error}`,
      timestamp: new Date().toISOString()
    }
    if (token) {
      logWebchatHost(logsUrl, token, log)
    }
    return null
  }
}

export const moveAttachmentsWebchatToAttachments = activity => {
  activity.attachments = activity.attachmentsWebchat
  delete activity.attachmentsWebchat
  delete activity.name
}

export const extractContentTypesFromBase64 = baseString => baseString.match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0]

export const logWebchatHost = (logsUrl, token, log) => {
  // Fire and forget logging because we don't want to block the user experience.
  fetch(logsUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body: JSON.stringify([log])
  }).catch(err => {
    console.error('Failed to log file upload.', err)
  })
}
