import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import TimeaWebchat from 'features/chat/webchat/TimeaWebchat'
import { connectToChatbot } from './webchat/functions'

const ChatAnonymous = ({
  isAnonymous,
  urlConfig,
  displayHeader,
  displayChatbotName,
  headerFollowupText,
  hideUploadButton,
  hideNewConversationButton,
  botAvatar,
  soundUrl,
  forceAutoscrollOnNewMessage,
  isDevelopment,
  headerChatVersion,
  emojiSet
}) => {
  const displayUserName = ''

  const handleConnectToDirectline = useCallback(
    (userID, username, fullTokenUrl, getNewConversation, setDirectline) => {
      connectToChatbot(isAnonymous, fullTokenUrl, userID, username, null, getNewConversation, setDirectline)
    },
    [isAnonymous]
  )

  return (
    <div id='tswebchat_container'>
      <TimeaWebchat
        handleConnectToDirectline={handleConnectToDirectline}
        isAnonymous={isAnonymous}
        urlConfig={urlConfig}
        displayHeader={displayHeader}
        displayChatbotName={displayChatbotName}
        displayUserName={displayUserName}
        headerFollowupText={headerFollowupText}
        hideUploadButton={hideUploadButton}
        hideNewConversationButton={hideNewConversationButton}
        botAvatar={botAvatar}
        soundUrl={soundUrl}
        forceAutoscrollOnNewMessage={forceAutoscrollOnNewMessage}
        isDevelopment={isDevelopment}
        headerChatVersion={headerChatVersion}
        emojiSet={emojiSet}
      />
    </div>
  )
}

ChatAnonymous.propTypes = {
  isAnonymous: PropTypes.bool.isRequired,
  urlConfig: PropTypes.object.isRequired,
  displayHeader: PropTypes.bool,
  displayChatbotName: PropTypes.object.isRequired,
  headerFollowupText: PropTypes.object.isRequired,
  hideUploadButton: PropTypes.bool,
  hideNewConversationButton: PropTypes.bool,
  botAvatar: PropTypes.object,
  soundUrl: PropTypes.string,
  forceAutoscrollOnNewMessage: PropTypes.bool,
  isDevelopment: PropTypes.bool,
  headerChatVersion: PropTypes.string,
  emojiSet: PropTypes.bool || PropTypes.object
}

export default ChatAnonymous
