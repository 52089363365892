import { createDirectLine } from 'botframework-webchat'

export const getDirectLine = async (userID, username, getNewConversation, tokenUrl, token) => {
  let headers = {
    'Content-Type': 'application/json'
  }
  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  }
  return await fetch(tokenUrl, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      userId: userID,
      username,
      getNewConversation
    })
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        console.error(data.error)
      } else {
        // eslint-disable-next-line no-unused-vars
        const { token, streamUrl, conversationId } = data
        return createDirectLine({ token })
      }
    })
}

// For generating an anonymous user token
export const getDirectLineAnonymous = async tokenUrl => {
  let headers = {
    'Content-Type': 'application/json',
    'Content-Length': 0
  }
  return await fetch(tokenUrl, {
    method: 'POST',
    headers: headers
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        console.error(data.error)
      } else {
        // eslint-disable-next-line no-unused-vars
        const { token, streamUrl, conversationId } = data
        return createDirectLine({ token })
      }
    })
}

export const getUsernameInitialsForAvatar = username =>
  username
    ?.split(' ', 2)
    .map(x => x.charAt(0))
    .join('')
    .toUpperCase() || 'US'

export const defaultStyleOptions = {
  autoScrollSnapOnPage: true
}

export const createStyleOptions = (styleOptions = {}) => ({ ...defaultStyleOptions, ...styleOptions })

export const forceScrollDown = document => {
  const htmlTranscriptActivities = document.querySelectorAll('.webchat__basic-transcript__activity')
  if (htmlTranscriptActivities && htmlTranscriptActivities.length > 0) {
    const last = htmlTranscriptActivities[htmlTranscriptActivities.length - 1]
    last.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }
}

export const connectToChatbot = (isAnonymous, fullTokenUrl, userID, username, accessToken, getNewConversation, setDirectLine) => {
  const executeFetch = async () => {
    const dl = isAnonymous
      ? await getDirectLineAnonymous(fullTokenUrl)
      : await getDirectLine(userID, username, getNewConversation, fullTokenUrl, accessToken)
    setDirectLine(dl)
  }
  executeFetch()
}

export const createConfig = (styleSet, botAvatar, hideUploadButton, customLocalization, emojiSet) => {
  let config = {
    styleSet,
    styleOptions: {
      adaptiveCardsParserMaxVersion: '1.5'
    }
  }

  if (styleSet) {
    config.styleSet = styleSet
  }

  if (botAvatar) {
    config.styleOptions.botAvatarImage = botAvatar.image
    config.styleOptions.botAvatarInitials = botAvatar.initials
  }

  if (hideUploadButton) {
    config.styleOptions.hideUploadButton = hideUploadButton
  }

  if (customLocalization) {
    config.overrideLocalizedStrings = (strings, language) => {
      if (customLocalization[language]) {
        return {
          ...strings,
          ...customLocalization[language]
        }
      } else {
        return strings
      }
    }
  }
  config.styleOptions.emojiSet = emojiSet
  return config
}
