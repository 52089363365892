const { env } = require('utils/env')
const isAnonymous = JSON.parse(env.REACT_APP_WEBCHAT_IS_ANONYMOUS) // Modified for Anonymous Webchat

const urlConfig = {
  baseUrl: `${env.REACT_APP_BOT_LOGIC_API}/api/directline${isAnonymous ? '/anonymous' : ''}`,
  tokenUrl: '/token',
  uploadUrl: '/upload',
  logsUrl: '/logs'
}

export default urlConfig
