import React, { useState, useEffect, useMemo, useCallback } from 'react'
import ReactWebChat, { createStore } from 'botframework-webchat'
import PropTypes from 'prop-types'
import useSound from 'use-sound'
import { useTranslation } from 'react-i18next'
import { useHeader } from 'providers/AreasProvider'
import { IconButton } from '@bit/totalsoft_oss.react-mui.kit.core'
import { Refresh } from '@material-ui/icons'

import { forceScrollDown, createConfig } from './functions'
import WebchatHeader from './WebchatHeader'
import {
  DIRECT_LINE_POST_ACTIVITY_FULFILLED,
  WEB_CHAT_SEND_FILES,
  LANGUAGE_CHANGED,
  WEBCHAT_UPLOAD,
  DIRECT_LINE_INCOMING_ACTIVITY
} from '../../../constants/webchat/activityNames'
import { BOT } from '../../../constants/webchat/roles'
import { EVENT } from '../../../constants/webchat/activityTypes'
import autoscrollActivityTypes from '../../../constants/webchat/autoscrollActivityTypes'
import { moveAttachmentsWebchatToAttachments, sendFilesToChatbot } from './upload'
import { getOidcAccessToken } from 'utils/auth/oidcFunctions'

const TimeaWebchat = ({
  userID,
  username,
  // isAnonymous,
  urlConfig,
  styleSet,
  displayHeader,
  displayChatbotName,
  headerFollowupText,
  botAvatar,
  hideUploadButton,
  hideNewConversationButton,
  customLocalization,
  emojiSet,
  soundUrl,
  forceAutoscrollOnNewMessage,
  isDevelopment,
  headerChatVersion,
  handleConnectToDirectline
}) => {
  const { baseUrl, tokenUrl, uploadUrl, logsUrl } = urlConfig
  const { i18n, t } = useTranslation()
  const locale = i18n.language
  const [, setHeader] = useHeader()

  const [directLine, setDirectLine] = useState()
  const fullTokenUrl = baseUrl + tokenUrl
  const fullUploadUrl = baseUrl + uploadUrl
  const fullLogsUrl = baseUrl + logsUrl

  const [playMessageSound] = useSound(soundUrl)

  const store = useMemo(
    () =>
      createStore({}, ({ dispatch }) => next => async action => {
        const { type } = action
        if (forceAutoscrollOnNewMessage && autoscrollActivityTypes.includes(type)) {
          forceScrollDown(document)
        }

        switch (type) {
          case DIRECT_LINE_INCOMING_ACTIVITY: {
            const {
              activity: {
                from: { role },
                name,
                type,
                value
              }
            } = action.payload
            if (role === BOT && type === EVENT && name === LANGUAGE_CHANGED) {
              i18n.changeLanguage(value)
            }
            return next(action)
          }
          case WEB_CHAT_SEND_FILES:
            if (uploadUrl) {
              const { accessToken: latestAccessToken } = getOidcAccessToken()
              try {
                await sendFilesToChatbot(dispatch, action, fullUploadUrl, fullLogsUrl, latestAccessToken)
              } catch (err) {
                console.error(err)
              }
              return
            } else {
              return next(action)
            }
          case DIRECT_LINE_POST_ACTIVITY_FULFILLED: {
            playMessageSound()
            return next(action)
          }
          default:
            return next(action)
        }
      }),
    [playMessageSound, forceAutoscrollOnNewMessage, uploadUrl, fullUploadUrl, fullLogsUrl, i18n, directLine] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const activityMiddleware = useMemo(
    () =>
      () =>
      next =>
      (...setupArgs) => {
        const [card] = setupArgs
        if (card) {
          let { activity } = card
          // Trick webchat into displaying the attachments as a message from custom upload.
          if (uploadUrl && activity.name === WEBCHAT_UPLOAD) {
            moveAttachmentsWebchatToAttachments(activity)
          }
        }
        return next(card)
      },
    [uploadUrl]
  )

  const handleGetNewConversation = useCallback(() => {
    handleConnectToDirectline(userID, username, fullTokenUrl, true, setDirectLine)
  }, [fullTokenUrl, userID, username, setDirectLine, handleConnectToDirectline])

  useEffect(() => {
    handleConnectToDirectline(userID, username, fullTokenUrl, false, setDirectLine)
  }, [fullTokenUrl, userID, username, setDirectLine]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!hideNewConversationButton) {
      setHeader(
        <IconButton id='chatbot_newConversationBtn' color='theme' onClick={handleGetNewConversation} tooltip={t('Chatbot.NewConversation')}>
          <Refresh color='inherit' />
        </IconButton>
      )
    }
  }, [setHeader, t, hideNewConversationButton, handleGetNewConversation])

  const config = useMemo(
    () => createConfig(styleSet, botAvatar, hideUploadButton, customLocalization, emojiSet),
    [botAvatar, customLocalization, emojiSet, hideUploadButton, styleSet]
  )

  return (
    <>
      {displayHeader && (
        <WebchatHeader
          displayChatbotName={displayChatbotName}
          headerFollowupText={headerFollowupText}
          locale={locale}
          isDevelopment={isDevelopment}
          headerChatVersion={headerChatVersion}
        />
      )}
      <div className='chatbot-body'>
        {!!directLine && (
          <ReactWebChat
            directLine={directLine}
            store={store}
            userID={userID}
            username={username}
            locale={locale}
            activityMiddleware={activityMiddleware}
            {...config}
          />
        )}
      </div>
      <div className='chatbot-version'>
        <span hidden={!isDevelopment}>{headerChatVersion}</span>
      </div>
    </>
  )
}

TimeaWebchat.propTypes = {
  userID: PropTypes.string,
  username: PropTypes.string,
  isAnonymous: PropTypes.bool.isRequired,
  urlConfig: PropTypes.object.isRequired,
  styleSet: PropTypes.object,
  displayHeader: PropTypes.bool,
  displayUserName: PropTypes.string,
  displayChatbotName: PropTypes.object.isRequired,
  headerFollowupText: PropTypes.object.isRequired,
  botAvatar: PropTypes.object,
  customLocalization: PropTypes.object,
  hideUploadButton: PropTypes.bool,
  hideNewConversationButton: PropTypes.bool,
  emojiSet: PropTypes.bool || PropTypes.object,
  soundUrl: PropTypes.string,
  forceAutoscrollOnNewMessage: PropTypes.bool,
  isDevelopment: PropTypes.bool,
  headerChatVersion: PropTypes.string,
  handleConnectToDirectline: PropTypes.func.isRequired
}

TimeaWebchat.defaultProps = {
  isAnonymous: false,
  displayHeader: true,
  hideUploadButton: false,
  soundUrl: '',
  emojiSet: false,
  forceAutoscrollOnNewMessage: false,
  isDevelopment: false,
  headerChatVersion: '0.0.0'
}

export default TimeaWebchat
